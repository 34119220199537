
.myCheckArchive .am-list .am-list-item.am-textarea-item{
  padding-left: 0 !important;
}

.myCheckArchive .am-list-item{
  margin-bottom: 5px;
  padding-left: 0 !important;
}

.myCheckArchive .am-list-item .am-input-label.am-input-label-5, .myCheckArchive .am-list-item .am-list-line .am-list-content, .myCheckArchive .am-textarea-label.am-textarea-label-5 {
  width: 120px;
  background: #ddd;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  flex: initial;
  margin-right: 5px;
}
.myCheckArchive .am-list-item .am-list-line .am-list-extra{
  flex-basis: auto;
}
