
.myList .am-list .am-list-item.am-textarea-item{
  padding-left: 0 !important;
}

.myList .am-list-item{
  margin-bottom: 5px;
  padding-left: 0 !important;
}

.myList .am-list-item .am-input-label.am-input-label-5, .myList .am-list-item .am-list-line .am-list-content, .myList .am-textarea-label.am-textarea-label-5 {
  width: 120px;
  background: #108ee9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  flex: initial;
  margin-right: 5px;
  color: #fff
}
.myList .am-list-item .am-list-line .am-list-extra{
  flex-basis: auto;
}
.my-radio .am-radio {
  padding: 2.5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 5px;
}
